/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';

const InfoTop = (): JSX.Element => {
  return (
    <div className="infoTop-style">
      <div className="maxContainer">
        <h1 className="headingConfianza">AXÓN, TU LIBRERÍA DE CONFIANZA</h1>
        <h2 className="subTitleSp">
          Somos libreros con muchos años de especialización en Ciencias de la Salud y nos encanta
          hablar con nuestros clientes
        </h2>
        <div className="contentWrapper">
          <div className="wfull">
            <img src="/img/banner-libreros.jpg" alt="operadora" className="dstop" />
            <img src="/img/librerosMv.jpg" alt="operadora" className="mv" />
          </div>
          <div className="wfull centered">
            <h2>
              Somos libreros con muchos años de especialización en Ciencias de la Salud y nos
              encanta hablar con nuestros clientes
            </h2>
            <div className="contWrap">
              <div className="textWrap">
                <p>
                  ¿No encuentras un producto en nuestro catálogo, necesitas asesoramiento o un
                  presupuesto, tienes algún problema con nuestro servicio? No te pediremos que
                  rellenes formularios, ni te obligaremos a hablar con una centralita “inteligente”.
                </p>
                <p>
                  ¿Llámanos al{' '}
                  <a href="tel:+34915 939 999" className="specialText">
                    <strong>915 939 999</strong>
                  </a>{' '}
                  o usa nuestro
                  <span className="specialText">
                    <strong> LiveChat</strong>
                  </span>
                  . En este
                  <a href="/contacto" className="specialText">
                    <strong> horario</strong>
                    <img src="/img/externalLink.svg" alt="link" />
                  </a>
                  , te atenderá inmediatamente uno de nuestros expertos, fuera de él, déjanos tu
                  mensaje y te responderemos en cuanto regresemos.
                </p>
              </div>
              <div className="imgWrap">
                <img src="/img/tel-axon.svg" alt="telefono" />
                <img src="/img/live-chat-blue.svg" alt="live-chat" className="lifeChat" />
                <img src="/img/info.svg" alt="axon-mail" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(InfoTop);
