import React, { useEffect, useState } from 'react';
import Head from 'next/head';
import { useReactiveVar } from '@apollo/client';
import dynamic from 'next/dynamic';
import * as Sentry from '@sentry/nextjs';

import Organisms from '../src/components/common/homeRestyle/Organisms';
import {
  Area,
  Book,
  BooksFeaturedDocument,
  BooksFeaturedQuery,
  BooksNewsDocument,
  BooksNewsQuery,
  GetAreasDocument,
  GetAreasQuery,
} from '../src/graphqlTypes';
import constants from '../src/utils/constants';
import useWindowSize from '../src/hooks/useWindowSize';
import { addApolloState, initializeApollo } from '../src/graphql/apollo';
import userReactiveVar from '../src/hooks/useReactiveVariableUser';
import OptionsHomeProduct from '../src/components/common/homeRestyle/homeRestyle.model';
import WhiteSpace from '../src/components/common/homeRestyle/WhiteSpace';
import InfoTop from '../src/components/common/homeRestyle/InfoTop';

const Related = dynamic(() => import('../src/components/common/related/Related'));
const MainLayout = dynamic(() => import('../src/layouts/MainLayout/MainLayout'));
const PageContent = dynamic(() => import('../src/layouts/PageContent'));
const Newsletter = dynamic(() => import('../src/components/newsletter/Newsletter'));
const HomeSections = dynamic(() => import('../src/components/common/homeRestyle/HomeSections'));

const ProductsHome = dynamic(() => import('../src/components/common/homeRestyle/ProductsHome'));
const Toast = dynamic(() => import('../src/components/Toast'));

const slideOff = false;

interface HomeProps {
  booksFeatured: Book[];
  booksNews: Book[];
  listAreas: Area[];
}
interface HomeState {
  modal: boolean;
  openNewsletter: boolean;
  thank: boolean;
  isMobile: boolean;
  orientation: string;
  newBooks: Book[];
  recentlyViewed: number;
}

const Home = ({ booksFeatured, booksNews, listAreas }: HomeProps): JSX.Element => {
  const user = useReactiveVar(userReactiveVar);
  const { height, documentWidth } = useWindowSize();
  const [state, setState] = useState<HomeState>({
    modal: false,
    openNewsletter: null,
    thank: true,
    isMobile: false,
    orientation: 'landscape',
    newBooks: [],
    recentlyViewed: 0,
  });
  const existSusbscription = user?.profile?.newsletter?.areaOfInterest?.length > 0;

  useEffect(() => {
    Sentry.setTag('isBeta', constants.isBeta);
    if (user?.email) {
      Sentry.setUser({ email: user?.email, id: user._id });
    }
  }, []);

  const getOrientation = (): void => {
    if (documentWidth < height) {
      setState((prevState) => ({ ...prevState, orientation: 'portrait' }));
    } else {
      setState((prevState) => ({ ...prevState, orientation: 'landscape' }));
    }
    if (window && window.innerWidth <= 768) {
      setState((prevState) => ({ ...prevState, isMobile: true }));
    }
  };

  useEffect(() => {
    getOrientation();
  }, []);

  const setRecentlyViewed = (recent): void => {
    setState((prevState) => ({ ...prevState, recentlyViewed: recent }));
  };

  return (
    <>
      <Head>
        <title>AXON</title>
      </Head>

      <Toast />

      <MainLayout className='homeNew'>
        <PageContent id="home">
          <>
            <div className="row homeSectionsWrapper">
              <HomeSections />
            </div>
            <ProductsHome
              option={OptionsHomeProduct.Features}
              title="Destacados"
              booksFeatured={booksFeatured}
            />

            {slideOff ? <WhiteSpace /> : <InfoTop />}
            <Organisms />
            <ProductsHome
              option={OptionsHomeProduct.New}
              reverse
              title="Nuevos"
              booksNews={booksNews}
            />

            {(!existSusbscription || state.thank) && (
              <div className="row newsletterWrapper">
                <Newsletter listAreas={listAreas} />
              </div>
            )}

            {user ? (
              <Related
                setRecentlyViewed={setRecentlyViewed}
                recentViews
                home
                idUser={user?._id || null}
                title="Vistos Recientemente"
              />
            ) : null}


          </>
        </PageContent>
      </MainLayout>
    </>
  );
};

export const getStaticProps = async (): Promise<Record<string, unknown>> => {
  const apolloClient = initializeApollo();

  const getBooksNews = apolloClient.query<BooksNewsQuery>({
    query: BooksNewsDocument,
    fetchPolicy: 'network-only',
  });

  const getBooksFeatured = apolloClient.query<BooksFeaturedQuery>({
    query: BooksFeaturedDocument,
    fetchPolicy: 'network-only',
  });

  const getAreas = apolloClient.query<GetAreasQuery>({
    query: GetAreasDocument,
  });

  const [
    {
      data: { booksNews },
    },
    {
      data: { booksFeatured },
    },
    {
      data: { getAreas: listAreas },
    },
  ] = await Promise.all([getBooksNews, getBooksFeatured, getAreas]);

  return addApolloState(apolloClient, {
    props: {
      booksNews,
      booksFeatured,
      listAreas,
    },
    revalidate: 3600,
  });
};

export default Home;
