import { useEffect, useState } from 'react';

interface WindowSize {
  documentWidth: number;
  height: number;
}

/**
 * Custom Hook calculate the size of the window
 * @returns a object with the height and the width of Document
 */

function useWindowSize(): WindowSize {
  const [windowSize, setWindowSize] = useState<WindowSize>({
    documentWidth: 0,
    height: 0,
  });

  useEffect(() => {
    const handleWindow = (): void => {
      setWindowSize({
        documentWidth: window.innerWidth,
        height: window.innerHeight,
      });
    };

    handleWindow();

    window.addEventListener('resize', handleWindow);

    return (): void => {
      window.removeEventListener('resize', handleWindow);
    };
  }, []);

  return windowSize;
}

export default useWindowSize;
