/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import style from './style/organisms.module.css';
import Link from '../link/Link';

const Organisms = (): JSX.Element => {
  return (
    <div className="infoTop-style">
      <div className="maxContainer">
        <div className="wrapSeparator">
          <span className="separator" />
          <span className="separatorPink" />
        </div>

        <div className="contentWrapper2">
          <div className="wfull mv2">
            <img src="/img/organismos.jpg" alt="operadora" />
          </div>
          <div className="wfull centered">
            <div className="flexBlock">
              <img src="/img/B2B.svg" alt="organisms" className="b2b" />
              <h2 className="blueTitle">
                Industria Farmacéutica
                <br />
                Centros Hospitalarios
                <br />
                Bibliotecas Universitarias
                <br />
                Empresas
              </h2>
            </div>
            <div className="organismText">
              <p>
                Estamos capacitados y tenemos amplia experiencia como proveedores de organismos.
              </p>
              <p>
                Flexibilidad para adaptarnos a sus requerimientos de presupuestos, facturación y
                compliance.
              </p>
            </div>

            <Link href="/b2b" className={style.btn}>
              Quiero mas información
            </Link>
          </div>
          <div className="wfull dstop2">
            <img src="/img/organismos.jpg" alt="operadora" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Organisms);
